import { useState } from 'react';

import { useTranslation } from 'react-i18next'

import { TextField, MenuItem } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';


function SelectField({ choices, ...otherProps }) {
    return <TextField {...otherProps} select={true} fullWidth={true} variant='outlined'>
        {Object.keys(choices).map((item, pos) => {
            return <MenuItem key={pos} value={item}>
                {choices[item]}
            </MenuItem>
        })}
    </TextField>
}


function BooleanField({ ...otherProps }) {
    const { t } = useTranslation()

    return <SelectField choices={{ true: t("Yes"), false: t("No") }} {...{ ...otherProps }} />
}


function ValueEntryField({ fieldMetadata, ...otherProps }) {
    const { t } = useTranslation()

    const label = t(fieldMetadata.label)

    switch (fieldMetadata.type) {
        case "choice":
            return <SelectField
                label={label}
                choices={fieldMetadata.choices}
                {...otherProps}
            />
        case "boolean":
            return <BooleanField
                label={label}
                {...otherProps}
            />
        default:
            return <TextField label={label} {...otherProps} />
    }
}


/**
 * Make a field nullable by showing an add button when the field is null
 * to set a non-null value. When the field is not null, show the field
 * and a delete button to set the field to null.
 */
export function AddButtonNullableField({ fieldMetadata, ...otherProps }) {
    const { t } = useTranslation()

    const [showField, setShowField] = useState(!((otherProps.value === null || otherProps.value === undefined)))

    function hideField() {
        setShowField(false)
        otherProps.onChange({ target: { value: null } })
    }

    function doShowField() {
        setShowField(true)
        otherProps.onChange({ target: { value: "" } })
    }

    if (otherProps.value === null) {
        otherProps.value = '';
    }

    return (
        <Stack direction="row">
            {showField ?
                <>
                    <IconButton onClick={hideField}>
                        <DeleteIcon />
                    </IconButton>
                    <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
                </>
                :
                <>
                    <IconButton onClick={doShowField}>
                        <AddIcon />
                    </IconButton>
                    <p style={{ opacity: 0.5 }}>{t(fieldMetadata.label)}</p>
                </>
            }
        </Stack>
    )
}


/**
 * Make a field nullable by showing a boolean field to set the field to null.
 * The boolean field can have a custom label
 * When the field is not null, show the field and make it required.
 */
export function BooleanNullableField({ fieldMetadata, switchLabel = "", ...otherProps }) {

    const [showField, setShowField] = useState(!((otherProps.value === null || otherProps.value === undefined)))

    function hideField() {
        setShowField(false)
        otherProps.onChange({ target: { value: null } })
    }

    function doShowField() {
        setShowField(true)
        otherProps.onChange({ target: { value: "" } })
    }

    if (otherProps.value === null) {
        otherProps.value = '';
    }

    return (
        <Stack direction="row" spacing="0.5rem">
            <BooleanField
                label={switchLabel}
                value={showField}
                onChange={showField ? hideField : doShowField}
                size={otherProps.size}
            />
            {showField &&
                <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
            }
        </Stack>
    )
}


/**
 * Wrap ValueEntryField to handle nullable fields allowing to set them to null
 * by clicking on a button that hides the field, sets the value to null
 * and shows a button to show it again.
 * Only wrap when the field is nullable, this is checked on fieldMetadata.allow_null.
 */
export default function EditField({ fieldMetadata, ...otherProps }) {
    return (
        fieldMetadata.allow_null ?
            <AddButtonNullableField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
            :
            <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
    )

}
