import axios from "axios"

// export const BASE_URL = 'https://merchantnewapiqa.paygol.com/api/v2'
// export const BASE_URL = 'https://gateway.paygol.com/api/v2'
// export const BASE_URL = 'http://172.31.250.6:8000/api/v2'

export const BASE_URL = process.env.REACT_APP_BASE_URL

const CASHOUTS_URL = 'https://cashouts.paygol.com/api/v3'

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-type': 'application/json'
    }
})

export const axiosCashouts = axios.create({
    baseURL: CASHOUTS_URL,
    headers: {
        'Content-type': 'application/json'
    }
})
