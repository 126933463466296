import { GenericList, ExtraCol, GenericCrud } from 'components/Common/GenericList'
import TableActions from 'components/TableUI/TableActions'

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { useEffect } from 'react'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'
import { Card, CardContent, Grid, Stack } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PageTitle from 'components/Misc/PageTitle'


export default function MethodsList() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { methodId } = useParams()

    const api = useAxiosPrivate()

    const actions = (entry) => <TableActions actions={[
        {
            id: `edit-${entry.id}`,
            handleClick: () => { navigate(`${entry.id}/fees`) },
            icon: <MonetizationOnIcon />,
            title: t('Fees'),
        }
    ]} />

    const [methodData, setMethodData] = useState(null)

    useEffect(() => {
        api.get(`/internal/payment-methods/${methodId}/`).then((response) => {
            setMethodData(response.data.data)
        }
        )
    }, [methodId, api])

    return <Card>
        <CardContent>
            <Grid container spacing={2} mt={2} mb={2} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <PageTitle title={((methodData === null ? '' : `${methodData.name} (${methodData.method_provider.name}) / `)) + t("Submethods")} />
                        <ButtonWrapper
                            variant="text"
                            size="small"
                            onClick={() => navigate('/payment-methods')}
                            startIcon={<ArrowBackIcon />}>
                            {t('Go back')}
                        </ButtonWrapper>
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{
                    textAlign: 'left'
                }}>
                    <GenericCrud
                        url={`/internal/payment-methods/${methodId}/submethods/`}
                        extraCols={[ExtraCol("", actions)]}
                        hiddenFields={new Set(["id"])}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}
